export default {
    namespaced: true,

    state: () => ({
        ot_list: [],
    }),

    getters: {
        ot_list(state) {
            return state.ot_list;
        },
    },

    mutations: {
        ot_list(state, ot_list){
            state.ot_list = ot_list;
        },
    },

    actions: {
        async saveOvertime(ctx, otData) {
            let isSuccess = false;

            await axios.post(`/add-overtime`, otData)
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
        async updateOvertime(ctx, otData) {
            let isSuccess = false;

            await axios.post(`/update-overtime`, otData)
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
        async getOvertimeList(ctx, apiParams = {}) {
            let records = await axios.post(`/get_overtimes`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            return records;

        },

        async deleteOvertime(ctx, id) {
            let isSuccess = false;
            await axios.post(`/delete-overtime`, { id })
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                isSuccess = true;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
                isSuccess = false;
            })

            return isSuccess;
        },
    }
}
